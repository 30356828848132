import * as React from 'react';
import { GlobalStyles, Interstitial } from '@supersonic/components';
import { Container } from '@supersonic/components';
import { ThemeProvider } from '@supersonic/components';
import { graphql, PageProps } from 'gatsby';
import { ConfigTheme } from '@supersonic/config';
import styled from 'styled-components';

type DataProps = {
  config: {
    theme: ConfigTheme;
    titleSuffix: string;
    title: string;
  };
};

const MainStyled = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

const InterstitialPage = ({ data }: PageProps<DataProps>) => {
  return (
    <ThemeProvider theme={data.config.theme}>
      <MainStyled>
        <GlobalStyles />
        <Container>
          <Interstitial
            logo={'travelsupermarket'}
            logoColor={'primary'}
            imageSrc={
              'https://img.icelolly.com/interstitial/interstitial-IL.jpg'
            }
          />
        </Container>
      </MainStyled>
    </ThemeProvider>
  );
};

export default InterstitialPage;

export const query = graphql`
  query allSiteConfig {
    config {
      title
      titleSuffix
      ...Theme
      components {
        ...Footer
        ...Header
        ...Gadget
      }
    }
  }
`;
